import { useMutation, useQueryClient } from '@tanstack/react-query';
import { webQueries } from '@qb/httpRequest/webQueries';
import { useReduxDispatch } from '@/src/store/store';
import { resetCheckoutPageState } from '../../Checkout/store/reducer';

export const useUpsertCartPartRequest = (
  partID: number,
  onSuccess?: () => void,
) => {
  const dispatch = useReduxDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    ...webQueries.cartPart.upsert(partID),
    onSuccess: async () => {
      dispatch(resetCheckoutPageState());
      queryClient.invalidateQueries(webQueries.cart);

      onSuccess?.();
    },
  });
};
